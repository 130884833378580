import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from "./components/Navbar.js";
import Home from "./Home/Home.js";
import About from "./About/About.js";
import Product from "./Product/Product.js";
import Contact from "./Contact/Contact.js";
// import AssetLink from "./Assetlinks/Assestlink.js";
import * as Routes from "./Routes.js";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy.jsx";
import Terms from "./Terms/Terms.jsx";


function App() {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route exact path={Routes.Home} component={Home} />
        <Route exact path={Routes.About} component={About} />
        <Route exact path={Routes.Product} component={Product} />
        <Route exact path={Routes.ContactUs} component={Contact} />
        <Route exact path={Routes.PrivacyPolicy} component={PrivacyPolicy} />
        <Route exact path={Routes.TermsConditions} component={Terms} />
        {/* <Route exact path={Routes.AssetLinks} component={AssetLink} /> */}
      </Switch>
    </Router>
  );
}

export default App;
