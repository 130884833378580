//import React, { useEffect, useRef } from "react";
import Loding from "../components/Loding";
import "./home.css";
import heroimg from "../assets/home-img.png";
import playstore_button from "../assets/google-play-badge.png";
import applestore_button from "../assets/apple-badge.svg";
// import aboutimg from "./assets/[Mockup] iPhone X.png";
import mockup1 from "../assets/mukham-main.png";
import { useHistory } from "react-router-dom";
import Contactus from "../Contact/Contactus";
import { TypeAnimation } from 'react-type-animation';
import Footer from "../components/Footer";
// import Footer from "../components/Footer";

// const Alinks = "/about";
const Plinks = "/product";



function Home() {
  const history = useHistory();
  function LinkTO(link) {
    if (link === "") {
      console.log("Link not attached yet !!!!");
    } else {
      history.push({
        pathname: link,
      });
    }
  }
  return (
    <div>
      {/* <Loding /> */}
      <div className="homepage">
        {/* Hero section */}
        <section className="sec fixtop">
          <div className="hero-content">
            <div className="hero-content-left">
              <h3>Mukham</h3>
              <div className="orange-line" style={{ margin: "1em" }}></div>
              <p>
                <TypeAnimation
                sequence={[
                  // Same substring at the start will only be typed out once, initially
                  'Passwordless Authentication',
                  1000,
                  'Experience Our Award Winning Product',
                  1000, // wait 1s before replacing "Mice" with "Hamsters"
                  'Attendance made more faster',
                  1000,
                ]}
                wrapper="span"
                speed={50}
                style={{ fontSize: '1em', display: 'inline-block' }}
                repeat={Infinity}
              />
              </p>
              <div className="buttons">
                <a
                  href="https://mukham.in/app.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={playstore_button} alt="playstore" />
                </a>
                <a
                  className="app-button"
                  href="https://mukham.in/app.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={applestore_button} alt="applestore" />
                </a>
              </div>
            </div>
            <div className="hero-content-right">
              <img src={heroimg} alt="" />
            </div>
          </div>
        </section>

        {/* about section */}

        <section className="home-card">
          <div className="home-section">
            <h3>We Are</h3>
            <p>Mukham Inc, is a fully student driven Start-up accelerating from VIT-AP emerged with a goal to solve the problem of tedious manual attendance marking system.</p>
          </div>
        </section>

        {/* product section */}

        <section className="sec df">
          <div className="product_sec_content">
            <div className="product_sec_left">
              <h3 style={{ marginLeft: "10px", marginBottom: "10px" }}>
                Product
              </h3>
              <p>
                The application is integrated with Assistant to remind and
                process the flow. It consists of a complete simplified UI which
                takes less than 10 seconds to mark attendance. In addition to
                this it has a magnificent Complete Customized dashboards to take
                attendance reports. This indeed makes it easier both for the
                student as well as the educational institute, employee and the
                company. 
              </p>
              <br />
              <button className="button" onClick={() => Plinks && LinkTO(Plinks)}>
                Know more
              </button>
            </div>
            <div className="product_sec_right">
              <img src={mockup1} alt="" />
            </div>
          </div>
        </section>

        {/* contact form */}
        <section className="sec">
          <Contactus />
        </section>
      </div>

      <br/>
      <br/>
      <br/>
      <br/>

      {/* <Footer className="hero-footer"/> */}
      <Footer/>
    </div>
  );
}

export default Home;
