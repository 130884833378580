import React from 'react'
import './privacypolicy.css'
import Footer from '../components/Footer'

function PrivacyPolicy() {
        return (
            <>
                <div className="privacypolicypage">
                    <h1>Privacy Notice</h1>
                    <p>Last updated August 29, 2024</p>
                    <p>Thank you for choosing to be part of our community at MUKHAM ("Company," "we," "us," or "our"). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice or our practices with regard to your personal information, please contact us at <a href="mailto:contact@mukham.in">contact@mukham.in</a></p>
                    <p>This privacy notice describes how we might use your information if you:</p>
                    <ul>
                        <li>Download and use our mobile application MUKHAM</li>
                        <li>Engage with us in other related ways including any sales, marketing, or events</li>
                    </ul>
                    <p>In this privacy notice, if we refer to:</p>
                    <ul>
                        <li>"App," we are referring to any application of ours that references or links to this policy, including any listed above</li>
                        <li>"Services," we are referring to our App, and other related services, including any sales, marketing, or events</li>
                    </ul>
                    <p>The purpose of this privacy notice is to explain to you in the clearest way possible what information we collect, how we use it, and what rights you have in relation to it. If there are any terms in this privacy notice that you do not agree with, please discontinue use of our Services immediately.</p>
                    <p>Please read this privacy notice carefully, as it will help you understand what we do with the information that we collect.</p>

                    <h2>TABLE OF CONTENTS</h2>
                    <ul>
                        <li><a href="#section1">1. WHAT INFORMATION DO WE COLLECT?</a></li>
                        <li><a href="#section2">2. HOW DO WE USE YOUR INFORMATION?</a></li>
                        <li><a href="#section3">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a></li>
                        <li><a href="#section4">4. WHO WILL YOUR INFORMATION BE SHARED WITH?</a></li>
                        <li><a href="#section5">5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a></li>
                        <li><a href="#section6">6. HOW LONG DO WE KEEP YOUR INFORMATION?</a></li>
                        <li><a href="#section7">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</a></li>
                        <li><a href="#section8">8. WHAT ARE YOUR PRIVACY RIGHTS?</a></li>
                        <li><a href="#section9">9. CONTROLS FOR DO-NOT-TRACK FEATURES</a></li>
                        <li><a href="#section10">10. DO WE MAKE UPDATES TO THIS NOTICE?</a></li>
                        <li><a href="#section11">11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a></li>
                        <li><a href="#section12">12. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?</a></li>
                    </ul>
                    <section id='section1'>
                        <h2>1. WHAT INFORMATION DO WE COLLECT?</h2>
                        <p>
                            <strong>Personal information you disclose to us</strong>
                            <br />
                            <em>In Short:</em> We collect personal information that you provide to us.
                        </p>
                        <p>
                            We collect personal information that you voluntarily provide to us
                            when you register on the App, express an interest in obtaining
                            information about us or our products and Services, when you
                            participate in activities on the App or otherwise when you contact us.
                        </p>
                        <p>
                            The personal information that we collect depends on the context of
                            your interactions with us and the App, the choices you make and the
                            products and features you use. The personal information we collect may
                            include the following:
                        </p>
                        <ul>
                            <li>
                                <strong>Personal Information Provided by You.</strong> We collect
                                names; email addresses; employee id; school; and other similar
                                information.
                            </li>
                            <li>
                                <strong>Social Media Login Data.</strong> We may provide you with the
                                option to register with us using your existing social media account
                                details, like your Facebook, Twitter or other social media account.
                                If you choose to register in this way, we will collect the
                                information described in the section called "HOW DO WE HANDLE YOUR
                                SOCIAL LOGINS?" below.
                            </li>
                        </ul>
                        <p>
                            All personal information that you provide to us must be true,
                            complete, and accurate, and you must notify us of any changes to such
                            personal information.
                        </p>
                        <p>
                            <strong>Information automatically collected</strong>
                            <br />
                            <em>In Short:</em> Some information such as your Internet Protocol
                            (IP) address and/or browser and device characteristics is collected
                            automatically when you visit our App.
                        </p>
                        <p>
                            We automatically collect certain information when you visit, use or
                            navigate the App. This information does not reveal your specific
                            identity (like your name or contact information) but may include
                            device and usage information, such as your IP address, browser and
                            device characteristics, operating system, language preferences,
                            referring URLs, device name, country, location, information about how
                            and when you use our App and other technical information. This
                            information is primarily needed to maintain the security and operation
                            of our App, and for our internal analytics and reporting purposes.
                        </p>
                        <ul>
                            <li>
                                <strong>Location Data.</strong> We collect location data such as
                                information about your device's location, which can be either
                                precise or imprecise. How much information we collect depends on the
                                type and settings of the device you use to access the App. For
                                example, we may use GPS and other technologies to collect
                                geolocation data that tells us your current location (based on your
                                IP address).
                            </li>
                        </ul>
                        <p>
                            <strong>Information collected through our App</strong>
                            <br />
                            <em>In Short:</em> We collect information regarding your geolocation,
                            mobile device, when you use our App.
                        </p>
                        <p>
                            If you use our App, we also collect the following information:
                        </p>
                        <ul>
                            <li>
                                <strong>Geolocation Information.</strong> We may request access or
                                permission to and track location-based information from your mobile
                                device, either continuously or while you are using our App, to
                                provide certain location-based services. If you wish to change our
                                access or permissions, you may do so in your device's settings.
                            </li>
                            <li>
                                <strong>Mobile Device Access.</strong> We may request access or
                                permission to certain features from your mobile device, including
                                your mobile device's camera, reminders, SMS messages, social media
                                accounts, and other features. If you wish to change our access or
                                permissions, you may do so in your device's settings. This
                                information is primarily needed to maintain the security and
                                operation of our App, for troubleshooting and for our internal
                                analytics and reporting purposes.
                            </li>
                        </ul>
                    </section>

                    <section id='section2'>
                        <h2>2. HOW DO WE USE YOUR INFORMATION?</h2>
                        <p>
                            <em>In Short:</em> We process your information for purposes based on
                            legitimate business interests, the fulfilment of our contract with
                            you, compliance with our legal obligations, and/or your consent.
                        </p>
                        <p>
                            We use personal information collected via our App for a variety of
                            business purposes described below. We process your personal
                            information for these purposes in reliance on our legitimate business
                            interests, in order to enter into or perform a contract with you, with
                            your consent, and/or for compliance with our legal obligations. We
                            indicate the specific processing grounds we rely on next to each
                            purpose listed below.
                        </p>
                        <ul>
                            <li>
                                <strong>To facilitate account creation and logon process.</strong> If
                                you choose to link your account with us to a third-party account
                                (such as your Google or Facebook account), we use the information
                                you allowed us to collect from those third parties to facilitate
                                account creation and logon process for the performance of the
                                contract. See the section below headed "HOW DO WE HANDLE YOUR SOCIAL
                                LOGINS?" for further information.
                            </li>
                            <li>
                                <strong>To post testimonials.</strong> We post testimonials on our App
                                that may contain personal information. Prior to posting a
                                testimonial, we will obtain your consent to use your name and the
                                content of the testimonial. If you wish to update, or delete your
                                testimonial, please contact us at
                                <a href="mailto:contact@mukham.in">contact@mukham.in</a> and
                                be sure to include your name, testimonial location, and contact
                                information.
                            </li>
                            <li>
                                <strong>Request feedback.</strong> We may use your information to
                                request feedback and to contact you about your use of our App.
                            </li>
                            <li>
                                <strong>To manage user accounts.</strong> We may use your information
                                for the purposes of managing your account and keeping it in working
                                order.
                            </li>
                            <li>
                                <strong>To send administrative information to you.</strong> We may use
                                your personal information to send you product, service and new
                                feature information and/or information about changes to our terms,
                                conditions, and policies.
                            </li>
                            <li>
                                <strong>To protect our Services.</strong> We may use your information
                                as part of our efforts to keep our App safe and secure (for example,
                                for fraud monitoring and prevention).
                            </li>
                            <li>
                                <strong>To enforce our terms, conditions, and policies</strong> for
                                business purposes, to comply with legal and regulatory requirements
                                or in connection with our contract.
                            </li>
                            <li>
                                <strong>To respond to legal requests and prevent harm.</strong> If we
                                receive a subpoena or other legal request, we may need to inspect
                                the data we hold to determine how to respond.
                            </li>
                            <li>
                                <strong>To deliver and facilitate delivery of services to the
                                    user.</strong> We may use your information to provide you with the
                                requested service.
                            </li>
                            <li>
                                <strong>To respond to user inquiries/offer support to users.</strong> We
                                may use your information to respond to your inquiries and solve any
                                potential issues you might have with the use of our Services.
                            </li>
                        </ul>
                    </section>
                    <section id='section3'>
                        <h2 id="shared">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h2>
                        <p><strong>In Short:</strong> We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfil business obligations.</p>
                        <p>We may process or share your data that we hold based on the following legal basis:</p>
                        <ul>
                            <li><strong>Consent:</strong> We may process your data if you have given us specific consent to use your personal information for a specific purpose.</li>
                            <li><strong>Legitimate Interests:</strong> We may process your data when it is reasonably necessary to achieve our legitimate business interests.</li>
                            <li><strong>Performance of a Contract:</strong> Where we have entered into a contract with you, we may process your personal information to fulfil the terms of our contract.</li>
                            <li><strong>Legal Obligations:</strong> We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).</li>
                            <li><strong>Vital Interests:</strong> We may disclose your information where we believe it is necessary to investigate, prevent, or act regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</li>
                        </ul>
                        <p>More specifically, we may need to process your data or share your personal information in the following situations:</p>
                        <ul>
                            <li><strong>Business Transfers:</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
                            <li><strong>Vendors, Consultants and Other Third-Party Service Providers:</strong> We may share your data with third-party vendors, service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work. Examples include data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow selected third parties to use tracking technology on the App, which will enable them to collect data on our behalf about how you interact with our App over time. This information may be used to, among other things, analyse and track data, determine the popularity of certain content, or features, and better understand online activity. Unless described in this notice, we do not share, sell, rent or trade any of your information with third parties for their promotional purposes.</li>
                        </ul>
                    </section>
                    <section id='section4'>
                        <h2 id="who">4. WHO WILL YOUR INFORMATION BE SHARED WITH?</h2>
                        <p><strong>In Short:</strong> We only share information with the following third parties.</p>
                        <p>We only share and disclose your information with the following third parties:</p>
                        <ul>
                            
                        </ul>
                        <p>If we have processed your data based on your consent and you wish to revoke your consent, please contact us using the contact details provided in the section below titled <a href="#contact">"HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"</a>.</p>

                    </section>
                    <section id='section5'>
                        <h2 id="social-logins">5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h2>
                        <p><strong>In Short:</strong> If you choose to register or log in to our services using a social media account, we may have access to certain information about you.</p>
                        <p>Our App offers you the ability to register and log in using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, profile picture.</p>
                        <p>We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant App. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use and share your personal information, and how you can set your privacy preferences on their sites and apps.</p>
                    </section>
                    <section id='section6'>
                        <h2 id="data-retention">6. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
                        <p><strong>In Short:</strong> We keep your information for as long as necessary to fulfil the purposes outlined in this privacy notice unless otherwise required by law.</p>
                        <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.</p>
                        <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
                    </section>
                    <section id='section7'>
                        <h2 id="data-security">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
                        <p><strong>In Short:</strong> We aim to protect your personal information through a system of organizational and technical security measures.</p>
                        <p>We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our App is at your own risk. You should only access the App within a secure environment.</p>
                    </section>
                    <section id='section8'>
                        <h2 id="privacy-rights">8. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
                        <p><strong>In Short:</strong> You may review, change, or terminate your account at any time.</p>
                        <p>If you have questions or comments about your privacy rights, you may email us at contact@mukham.in.</p>
                        <h2>Account Information</h2>
                        <p>If you would at any time like to review or change the information in your account or terminate your account, you can:</p>
                        <ul>
                            <li>Log in to your account settings and update your user account.</li>
                            <li>Contact us using the contact information provided.</li>
                        </ul>
                    <p><strong>Opting out of email marketing:</strong> You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list; however, we may still communicate with you, for example, to send you service-related emails that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes. To otherwise opt-out, you may:</p>
                    <ul>
                        <li>Access your account settings and update your preferences.</li>
                        <li>Contact us using the contact information provided.</li>
                    </ul>

                </section>
                <section id='section9'>
                    <h2 id="do-not-track">9. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
                    <p><strong>In Short:</strong> Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>

                </section>
                <section id='section10'>
                    <h2 id="updates">10. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
                    <p><strong>In Short:</strong> Yes, we will update this notice as necessary to stay compliant with relevant laws.</p>
                    <p>We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</p>

                </section>
                <section id='section11'>
                    <h2 id="contact">11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
                    <p>If you have questions or comments about this notice, you may email us at <a href="mailto:contact@mukham.in">contact@mukham.in</a> or by post to:</p>
                    <p><strong>MUKHAM</strong><br />
                        VIT-AP University, Amaravati-522237, Andhra Pradesh, India</p>
                </section>
                <section id='section12'>
                    <h2 id="data-access">12. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?</h2>
                    <p>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please contact us at <a href="mailto:contact@mukham.in">contact@mukham.in</a>.</p>

                </section>
            </div>
            <Footer/>
        </>
    )
}

export default PrivacyPolicy