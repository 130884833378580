import React from "react";
import Contactus from "./Contactus";

function Contact() {
  return <div>
    <Contactus/>
  </div>;
}

export default Contact;
